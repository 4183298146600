html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.contact_bannersc{
    padding-top: 14rem !important;
  padding-bottom: 14rem;
  background-image: url("../../public/contactbanner.webp");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.contact_header_content {
    justify-content: left;
    display: flex;
    height: 200px;
  }
.contact_header_text {
    color: #fff;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 40px;
    padding-top: 10%;
    /* padding-right: 13%; */
    text-shadow: 1px 1px #85f2f6;
    padding-left: 6%;
  }
  .contact_bg{
    padding-top: 50px;
    padding-bottom: 30px;
    position: relative;
  }
  .contact_bg::before{
    content: "";
    width: 450px;
    height: 450px;
    border-radius: 100%;
    background-color: #ea53c626;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .contact_bg::after{
    content: "";
    width: 450px;
    height: 450px;
    border-radius: 100%;
    background-color: #ea53c626;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

.contact_form{
    /* padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px; */
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 45px #6566ee3d;
    margin-top: 5%;
    padding: 40px 20px 70px 20px;
    position: relative;
    z-index: 2;
    margin: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2.6rem;
    margin-right: 3rem;

}
.contact_contentbox{
    width: 100%;
    background: #02569A;
    padding: 30px;
    height: 100%
}
.bold_txt{
    font-family: sans-serif;
    /* font-size: 25px; */
    font-weight: 600;
    margin-block-start: 10px;
    margin-block-end: 10px;
    text-shadow: 1px 1px #85f2f6;
    color: #1c5793;
}
.white_color{
    color:#fff !important;
    text-align: center;
}
.contact_white_txt{
    font-family: sans-serif;
    /* font-size: 25px; */
    font-weight: 600;
    margin-block-start: 10px;
    margin-block-end: 10px;
    text-shadow: 1px 1px #000;
    color: #ffffff;
}
.little_txt{
    color: #fff;
    font-family: sans-serif;
    font-size: 10px;
    font-weight: 300;
    margin-block-start: 10px;
    margin-block-end: 10px;
}
.contact_form_content{
    width: 99%;
    padding: 30px;
    box-shadow: 0px 10px 61px #4843433b
}
.conatct_header{
    color: #000;
    font-family: sans-serif;
    /* font-size: 20px; */
    font-weight: 300;
    width: max-content;
    margin-block-start: 10px;
    margin-block-end: 10px;
}
.conatct_text_header{
    color: #000;
    font-family: sans-serif;
    /* font-size: 20px; */
    font-weight: 300;
    width: max-content;
    margin-block-start: 10px;
    margin-block-end: 10px;
}
.input_field{
    width: 40rem;
    height: 3rem;
    border-radius: 1rem;
}
.submit_btn{
    width: 100%;
    /* display: flex; */
    justify-content: right;
    margin-top: 1rem
}
.submit_btn button{
    background: #1c569a !important
}
.conatct_btn{
    width: 160px;
    padding: 8px;
    border-radius: 30px;
    /* background: linear-gradient(to right, #e903bc8f 0%, #758ae4 100%); */
    border: none;
    outline: none;
    font-family: Roboto;
    /* font-size: 20px; */
    font-weight: 600;
    color: #fff;
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
    .contact_form{
        padding-left: 0px;
    }
    .contact_bannersc{
        padding-top: 10rem !important;
    padding-bottom: 10rem;
    }
    .contact_header_text{
        padding-left: 5%
    }
    .contact_form {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    .contact_bg {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
}

@media only screen and (max-width: 766px) {
    .contact_form {
        box-shadow: none !important;
        padding: 0px !important;
        margin: 0.2rem !important;
        margin-top: 0rem !important
      }
    .contact_bg{
        padding-top: 0px !important;
    }
    .contact_bg::before{
        display: none;
    }
    .contact_bg::after{
        display: none;
    }

    
    .contact_bannersc{
        height: 40px !important;
        padding-top: 5rem !important;
        padding-bottom: 5rem;
    }
    .contact_header_text{
        font-size: 35px;
        padding-left: 10px
    }
    .input_field{
        width: 18rem;
    }
    .conatct_text_header{
        /* width: 100%; */
        font-size: 18px;
    }
    .conatct_header{
        display: none;
    }
    .contact_form{
        padding-left: 0px;
    }
}