.card-wrapper {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    --container: 1160px;
    max-width: var(--container);
    margin: auto;
  }
  .teamGrid {
    display: grid;
    grid-template-columns: 32.33% 32.33% 32.33%;
    column-gap: 1.5%;
    margin-top: 100px;
  }
  .avatar {
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    text-align: center;
  }
  .teamcolinner {
    position: relative;
  }
  .avatar > img {
    width: 100px;
    margin: auto;
    border-radius: 50%;
    border: 1px solid rgb(170 170 173/ 1);
    box-shadow: 0px 3px 10px 3px rgb(170 170 173 / 0.5);
  }
  
  .teamcolinner {
    position: relative;
    border: 1px solid #ddd;
    min-height: 100px;
    background: #fff;
    z-index: 9;
  }
  .teamcol {
    padding: 3px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    transition: transform 1s ease-in-out;
  }
  .teamcol:hover {
    transform: translateY(-10px);
    box-shadow: 0px 3px 10px 3px rgb(170 170 173 / 0.5);
    transition: transform 1s ease-in-out;
  }
  .teamcol:before {
    content: "";
    width: 50%;
    height: 50%;
    position: absolute;
    right: 0;
    top: 0;
    background: -webkit-linear-gradient(#ffbf00, #ffa000);
    border-top-right-radius: 10px;
    transition: width 1s ease-in-out;
  }
  .teamcol:after {
    content: "";
    width: 50%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: -webkit-linear-gradient(#0052cc, #005fec);
    border-bottom-left-radius: 10px;
    transition: width 1s ease-in-out;
  }
  .teamcol:hover::before,
  .teamcol:hover::after {
    width: 100%;
    transition: width 1s ease-in-out;
  }
  .member-name {
    margin-top: 40px;
  }

  .member-name h6 {
    text-align: center !important;
  }

  .member-info {
    padding: 10px 20px;
  }

  .member-info p{
    text-align: justify;
  }
  
  