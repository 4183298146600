

.wrapper{
  padding-left: 10px;
}

.nav-container{
  
  box-shadow: 4px 4px 20px rgb(32, 193, 221);
  border-radius: 10px;
}

.tabs__content {
  padding: 30px;
  color: #c9bae2;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  line-height: 1.4;
  background: #463366;
}

.tabs__content h3 {
  color: #ffffff;
  font-weight: 700;
  font-family: inherit;
  font-size: 24px;
  margin-bottom: 10px;
}

.tabs__nav {
  display: flex;
  align-items: center;
  margin: 10px;
  list-style: none;
  background-color: #26d0ee;
  border-radius: 5px;
  padding: 7px 0px 7px 20px;
}

.tabs__button {
  background: rgba(204, 20, 20, 0);
  padding: 4px;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: block;
  position: relative;
  font-family: inherit;
  font-weight: 400;
}

.tabs__button:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background:#ff6e6c;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.4s ease-in-out;
}

.tabs__button.active::after {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.4s ease-in-out;
}

.tabs__item:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 784px) {
  .wrapper{
    padding-left:0px;
  }
}