@media only screen and (min-width: 767px) and (max-width: 991px) {
  .media_query {
    width: 100%;
    background-color: #ffffff;
  }
  .text {
    width: 100%;
    position: absolute;
    top: 110px !important;
    height: 123px;
    margin: 0rem !important;
  }
  .service_bg {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .hiring_bg {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .client_bg {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .css-o69gx8-MuiCardMedia-root {
    height: 18rem !important;
  }
  .sub_container {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .aboutus_section {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .aboutus_text {
    margin-top: 0rem !important;
  }
  .consultant_boxmargin {
    margin-right: 10px !important;
  }
  .consultant_iconbox {
    text-align: center;
    border-right: none !important;
  }
  .review_hedaertext {
    padding-left: 5% !important;
    padding-top: 20% !important;
  }
}
@media only screen and (max-width: 766px) {
  .carousel_img {
    width: 250px !important;
    margin-top: 10%;
    margin-left: 0px;
    /* position: relative !important; */
    right: 0px !important;
    top: 0px !important;
    /* margin-left: 35px !important;   */
    /* float: right; */
  }
  .text {
    width: 100%;
    position: absolute;
    top: 0px;
    /* height: 123px; */
    margin-top: 2rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .carousel_text {
    font-size: 24px !important;
    /* padding-top: 6%; */
    color: #7cf2f6 !important;
    text-shadow: 1px 1px #fff !important;
  }
  .carousel_large_text {
    font-size: 30px !important;
  }

  .media_query {
    width: 100%;
  }
  .MuiBox-root .css-i9gxme {
    width: fit-content;
  }
  .service_bg {
    padding-top: 0px !important;
  }
  .service_bg::after {
    display: none !important;
  }
  .aboutus_section::before {
    display: none;
  }
  .service_bg::before {
    display: none !important;
  }
  .barrow_box {
    display: none;
  }
  .sub_container {
    box-shadow: none !important;
    padding: 40px 40px 0px 40px !important;
    margin: 0.2rem !important;
    margin-top: 0rem !important;
  }
  .hover_txt {
    color: #98949461;
    font-family: sans-serif;
    font-size: 65px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    top: -50px;
    left: -10px;
    z-index: 1;
  }
  .aboutus_content {
    display: block !important;
  }
  .css-o69gx8-MuiCardMedia-root {
    width: 100% !important;
  }
  .aboutus_section {
    padding: 0px !important;
    overflow: visible !important;
  }
  .aboutus_section::before {
    display: none;
  }
  .aboutus_section::after {
    display: none;
  }
  .homeservices {
    font-size: 28px !important;
  }
  .homeservices_small_txt {
    font-size: 13px !important;
  }
  .image {
    margin-top: 8px !important;
  }
}
.top_header {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background-color: #0061a5 !important;
  color: white !important;
}
/* .css-m8ll50-MuiGrid-root{
    max-width: 74.5% !important;
  } */
/* .css-1c1ubpl{
    margin-left: 8px !important;
  } */
.css-k008qs {
  margin-left: 25px;
  margin-right: 25px;
}
/*============ResponsiveAppBar========== */
.navmenu {
  margin-left: 4px;
  margin-right: 4px;
  color: #2062a6;
  display: block;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px;
  cursor: pointer;
}
.navmenu:hover {
  color: black;
}

a {
  text-decoration: none !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #7ef2f6 !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  width: 51% !important;
}

.logo_hedaer {
  width: 8rem;
  height: auto;
  margin: 0.5rem;
  margin-left: 3.1rem;
}
header {
  background-color: "#a1dae5";
}

.sticky_header {
  position: sticky !important;
  top: 0px !important;
  width: 100%;
  z-index: 100 !important;
  /* position: fixed; */
  /* background: linear-gradient(to  bottom, #FFFFFF, #45ECF2) !important; */
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.gradient {
  background: linear-gradient(to bottom, #ffffff, #45ecf2) !important;
}
.css-19r6kue-MuiContainer-root {
  padding-right: 55px !important;
}

@media only screen and (max-width: 766px) {
  .logo_hedaer {
    margin-left: 0rem;
    width: 6rem;
    height: 3rem;
  }
  /* .css-480o17-MuiGrid-root>.MuiGrid-item{
      padding-top: 0px !important
    } */
}
/*============Carousel========== */

.carousel_slider {
  width: 72rem;
  height: 30rem;
}
.slick-track {
  width: 1150px !important;
}
.carousel_img {
  width: 30rem;
  float: right;
  position: absolute;

  right: 25px;

  top: 2.5rem;
}

.content_space {
  display: none;

  height: 24rem;

  /* margin-top: 2.2rem; */

  justify-content: space-between;

  background: linear-gradient(to left, #4e69f4, #4f6bf5) !important;

  /* background-image: url("../../public/Banner_img.webp"); */
}
.text {
  /* margin-top: 8rem; */
  padding: 10px;
  margin: 6rem;
  /* width: 100%; */
  position: absolute;
  top: 0px;
  /* margin: auto;
      width: 50%; */
}
.carousel_text {
  /* border: 3px solid green; */
  /* padding: 10px; */
  /* margin-top: 8rem; */
  color: #fff !important;
  text-shadow: 3px 3px #0061a5 !important;
  font-size: 40px;
  font-family: sans-serif;
  font-weight: 400;
  margin: 0px;
}
.carousel_large_text {
  font-family: sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 38px;
  margin: 0px;
}
.CheckButton {
  /* color:transparent; */
  background-color: #fff !important;
  border-radius: 36px !important;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 10% !important;
}

.MuiButtonBase-root
  .MuiIconButton-root
  .MuiIconButton-sizeMedium
  .css-ktslay-MuiButtonBase-root-MuiIconButton-root {
  border: 1px solid white;
}
.css-1m9128y {
  display: none;
}
.barrow_box {
  width: 40px;
  height: 70px;
  background: #fff;
  box-shadow: 0px 10px 30px #b43af32e;
  border-radius: 30px;
  position: relative;
  left: 50%;
  animation: animation 3s infinite !important;
  transform: translateX(-50%);
  /* transform: translateY(-50%); */
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  top: 10px;
}
.about_img {
  /* height: 350px !important; */
  width: 50% !important;
}

.aboutus_text {
  margin-top: 3rem;
}
@media only screen and (max-width: 766px) {
  .about_img {
    /* height: 380px !important;
      width: 100% !important; */
    display: none;
  }

  .aboutus_text {
    margin-top: 0rem;
    padding-left: 16px;
    /* padding-right: 16px */
  }
  .CheckButton {
    margin-top: 4% !important;
  }
  .content_space {
    margin-top: 0rem;
    height: 14rem !important;
  }
}
@media only screen and (min-width: 480px) {
}

/* @keyframes animation {
  
    from {top:0px; }
    to {top:50px;}
  
  } */

/* ======Services========== */

.service_bg {
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}

.service_bg::before {
  content: "";
  width: 86%;
  height: 450px;
  border-radius: 100%;
  background-color: #5653ea26;
  position: absolute;
  bottom: -43px;
  right: 69px;
}
.service_bg::after {
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  top: 0px;
  left: 0px;
}

.sub_container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 40px 20px 70px 20px;
  position: relative;
  z-index: 2;
  margin: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 4.6rem;
  margin-right: 4rem;
}
.sevice_header_txt {
  opacity: 1;
  transform: translateZ(0);
}
.homeservices {
  color: #1a5493;
  font-family: sans-serif;
  font-size: 45px;
  font-weight: 700;
  text-shadow: 1px 3px #9cf5f8;
  text-align: center;
  padding-top: 2%;
  margin-block-start: auto;
  margin-block-end: 25px;
}
.homeservices_small_txt {
  color: #2680eb;
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 32px;
}
.homeservices_imgbox {
  width: 100%;
  display: flex;
  justify-content: center !important;
}
.hover_txt {
  color: #98949461;
  font-family: sans-serif;
  font-size: 65px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: -50px;
  left: -10px;
  z-index: 1;
}
.service_grid {
  display: grid !important;
  grid-template-columns: 33% 33% 33%;
}
.service_content_box {
  width: 100%;
  position: relative;
  padding-top: 30px;
}

.image {
  width: 110px !important;
  margin-top: -10px;
}
.container {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.icon_box {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 4px 36px #765eec3d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
.icon {
  width: 45%;
}
.text_box {
  width: 20px;
  height: 3px;
  border-radius: 30px;
  background-color: rgb(185, 182, 182);
  margin-left: 8%;
  transition: width 0.5s, height 0.5s;
}
.text_box:hover {
  width: 170px;
  background-color: #00ff82;
}

.app_development_txt {
  color: #1b5493;
  text-shadow: 1px 2px #9cf5f8;
  /* font-family: Roboto; */
  font-size: 18px;
  font-weight: 500;
  /* padding-top: 10%; */
  text-transform: uppercase;
  z-index: 2;
  position: relative;
  margin: 0px !important;
  padding-top: 6%;
  padding-bottom: 0.5rem;
  padding: 1rem;
  align-self: center;
}

.app_development_paragrah {
  color: #000;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 300;
  /* padding-top: 11%; */
  /* padding-right: 10px; */
  text-align: justify;
  width: 100%;
}

@media only screen and (max-width: 766px) {
  .app_development_paragrah {
    width: 100%;
  }
  .service_grid {
    display: grid !important;
    grid-template-columns: 100%;
  }
  .sub_container {
    padding: 0px !important;
  }
}

/* =======AboutUS======= */

.aboutus_section {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
  padding-left: 75px;
  padding-right: 63px;
}

.aboutus_section::before {
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ff54108c;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
}

.aboutus_section::after {
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #ea53c6;
  position: absolute;
  top: 0px;
  left: 100px;
  z-index: -1;
}
.aboutus_container {
  background: linear-gradient(to right, #521d91 0%, #4a42ec 100%);
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee;
  padding: 40px 40px 0px 40px;
}
.aboutus_content {
  display: flex;
  /* flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
}
.css-o69gx8-MuiCardMedia-root {
  width: 100%;
}
.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}
.aboutus_header {
  /* color: #f4802d; */
  font-family: sans-serif;
  font-size: 35px;
  font-weight: 500;
  /* padding-top: 10%; */
  text-align: start;
  color: #fff;
  text-shadow: 2px 1px #0f0f0f;
}
.underline {
  width: 40px;
  height: 3px;
  border-radius: 30px;
  background-color: #00ff82;
}
.aboutus_details {
  color: #fff;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 300;
  text-align: justify;
  padding-top: 5%;
}
.technology_content {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 40px 20px 70px 20px;
  position: relative;
  z-index: 2;
  margin: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 4.6rem;
  margin-right: 4rem;
}
.technology_container {
  width: 70%;
  display: flex;
  /* justify-content: space-around; */
  margin-top: 5%;
  position: relative;
  margin: auto;
  margin-top: 4rem;
  /* background: #8169d7; */
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 2px #b59cc0;
  background: #fff;
}
.hire_header {
  font-family: sans-serif;
  font-size: 35px;
  font-weight: 500;
  /* padding-top: 10%; */
  text-align: start;
  color: #1a5493;
  text-shadow: 2px 1px #8ff4f8;
}
.hire_details {
  color: #515457;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
  padding-top: 5%;
  line-height: 1.5rem;
}
.hire_button {
  background: #045597;
  border-radius: 50px;
  padding: 7px;
  color: white;
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: 13px;
}
.hiring_bg {
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}
.hiring_bg::before {
  content: "";
  width: 86%;
  height: 450px;
  border-radius: 100%;
  background-color: #5653ea26;
  position: absolute;
  bottom: -43px;
  right: 69px;
}
.technology_content img {
  width: 30rem;
}

.css-1eoe787-MuiSlider-markLabel {
  color: #fc1c56;
  font-weight: 600;
}
.css-1eoe787-MuiSlider-markLabel:hover {
  color: #c0c0c0;
  font-weight: 500;
}
.css-1aznpnh-MuiSlider-root {
  color: #adadad;
}
.css-14pt78w-MuiSlider-rail {
  height: 2px !important;
}
.css-1eoe787-MuiSlider-markLabel {
  font-weight: 400;
  position: absolute;
  top: -20px !important;
}
.box_content {
  width: 100%;
  height: 110px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_content_txt {
  color: #000;
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
}
.technology_img {
  width: 35%;
  object-fit: cover;
}
.icon_content_width {
  position: relative;
  width: 100%;
  margin-right: 50px;
  /* padding-right: 15px;
      padding-left: 15px; */
}
.hiring_container {
  position: relative;
  margin: auto;
  margin-top: 4rem;
  /* background: #8169d7; */
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 2px #b59cc0;
  background: #fff;
  display: block;
  width: 89%;
}

@media only screen and (max-width: 766px) {
  .hiring_bg {
    padding-top: 0px !important;
  }
  .client_bg {
    padding-top: 0px !important;
  }
  .hiring_container {
    width: 95%;
  }
  .technology_content img {
    width: 19rem;
  }
  .box_content {
    height: auto;
  }
  .technology_img {
    width: 65%;
  }
  .aboutus_container {
    padding: 12px 15px 20px 0px !important;
  }
  .box_content_txt {
    font-size: 10px;
    padding-top: 10px;
    font-family: sans-serif;
  }
}

/* FOOTER */

.css-1oqqzyl-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* margin-left: 3% !important; */
  /* margin-right: 3% !important; */
}

.footer_sc {
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: none;
  border-top: 2px Solid #dafbfc;
  box-shadow: 0px 35px 54px #000;
  background: linear-gradient(to Bottom, white, #45ecf259);
  padding-left: 12px;
}
.footer_logo {
  width: 150px;
  height: 75px;
}
.about_txt {
  /* color: #03569a; */
  font-family: sans-serif;
  /* font-size: 20px; */
  font-weight: 500;
  margin-block-start: 10px;
  margin-block-end: 10px;
  text-align: start;
  color: #1b5493;
  text-shadow: 1px 2px #9cf5f8;
}
.footer_line {
  width: 40px;
  height: 3px;
  background-color: #00ff82;
  border-radius: 30px;
  margin-top: 4px;
}
.footer_paragraph {
  color: #3782b4;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;
  padding-top: 10px;
  padding-right: 30px;
}
.icon_text {
  color: #3782b4;
  font-family: sans-serif;
  /* font-size: 14px; */
  text-decoration: none;
  font-weight: 400;
  margin-block-start: 10px;
  margin-block-end: 10px;
}
.icon_text_link {
  color: #3782b4;
  font-family: sans-serif;
  /* font-size: 14px; */
  text-decoration: none;
  font-weight: 400;
  padding-top: 1.3rem;
}
.iframe_content {
  width: 100%;
  height: 230px;
}
.link_box {
  width: 50%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 4%; */
}
.round_container {
  width: 35px;
  height: 35px;
  background: #0256a0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
#iframeid {
  margin-top: 2%;
}
.consualt_section {
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
  margin-left: 12px;
  /* margin-right: 3.5rem; */
  width: 89.5% !important;
}
.consultant_boxmargin {
  margin-left: 10px !important;
}

.consultant_content {
  background: linear-gradient(to Right, #825efe, #13cece);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 10px 45px #6566ee;
}
.consultant_textbox {
  width: 24% !important;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  position: absolute !important;
  top: 60px;
  left: 50px;
  box-shadow: 0px 10px 45px #6566ee;
  z-index: 4;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
.portfolio_img_responsive {
  padding-left: 16px !important;
}
.consultant_headertext {
  color: #fc47008c;
  opacity: 1;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 500;
  margin-top: 10px;
  margin-block-start: auto;
  margin-block-end: auto;
  font-weight: bold;
  text-align: start;
  text-shadow: 1px 1px #9cf5f8;
}
.box_conatin {
  font-size: 15px;
  color: "#000";
  line-height: 2px;
  font-family: sans-serif;
  font-weight: 500;
  line-height: 1.4;
  margin-block-end: auto;
  text-align: justify;
}
.Enquiry_btn {
  display: inline-block !important;
  width: 30%;
  padding: 8px;
  background: linear-gradient(to right, #1a518f 0%, #144479 100%);
  box-shadow: 0px 10px 20px #6e62ed4f;
  border-radius: 30px;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-1%);
  border: none;
  color: #fff !important;
  text-transform: capitalize !important;
}
.enquiry_png {
  width: 65%;
  /* height: 9.5rem; */
}
.enquiry_section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 9px 14px 10px beige;
  padding: 10px;
}
.consult_btn {
  display: inline-block !important;
  width: 80%;
  padding: 8px;
  background: linear-gradient(to right, #825efe 0%, #13cece 100%);
  box-shadow: 0px 10px 20px #6e62ed4f;
  border-radius: 30px;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-1%);
  margin-top: 5% !important;
  border: none;
  color: #fff !important;
  text-transform: capitalize !important;
}
.consultant_iconbox {
  width: 100%;
  border-right: 3px Solid #00ff82;
  align-self: center;
}
.icon_lowertext {
  color: #fff;
  font-weight: 500;
  font-family: sans-serif;
  font-size: 12px;
}
.css-1ss2ei0-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 40%;
  padding: 10px;
  border-radius: 1rem;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.modal {
  font-size: 12px;
  display: contents !important;
}
.popup_logo {
  width: 60%;
}
.popup_header_txt {
  align-self: flex-end;
}
.modal > .header {
  width: 100%;
  /* border-bottom: 1px solid gray; */
  font-size: 24px;
  text-align: left;
  padding: 5px;
  font-family: sans-serif;
  color: #092a49;
  display: grid;
  grid-template-columns: 35% 45% 20%;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
  /* background-color: #d8f8f9; */
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  color: #000;
}
.form-label {
  font-size: 16px;
  font-family: sans-serif;
}
#Enter_typename {
  font-size: 15px !important;
  font-family: sans-serif !important;
}
#Enter_typeEmail {
  font-size: 15px !important;
  font-family: sans-serif !important;
}
#Enter_typePassword {
  font-size: 15px !important;
  font-family: sans-serif !important;
}
#Enter_typeMsg {
  font-size: 15px !important;
  font-family: sans-serif !important;
}

.popup_button {
  background: #1c569a !important ;
  border: none;
  width: 50%;
  padding: 6px;
  border-radius: 2px;
  text-align: center;
  font-size: 16px;
  font-family: sans-serif;
  color: #fff;
  /* font-weight: 800; */
}
.inner_content {
  padding-left: 10px;
}
.hire_text {
  margin-top: 3rem;
}

@media only screen and (max-width: 766px) {
  .hire_button {
    margin-bottom: 2rem;
    padding: 10px;
  }
  .hire_text {
    margin-top: 0rem;
  }
  .popup-content {
    width: 90%;
  }
  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 16px !important;
  }
  .iframe_content {
    width: 100% !important;
  }
  .consualt_section {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem;
    width: 80.5% !important;
    position: inherit !important;
    padding-top: 0px !important;
    margin-top: 2rem;
    padding-bottom: 40px;
  }
  .footer_sc {
    padding-left: 0px;
  }
  .footer_padding {
    padding: 10px;
  }
  .consultant_textbox {
    width: 100% !important;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    position: relative !important;
    top: -20px !important;
    left: -4px !important;
    box-shadow: 0px 10px 45px #6566ee;
    z-index: 4;
  }
  .consultant_iconbox {
    text-align: center;
    border-right: none;
  }
  /* .css-8atqhb{
      width: 100% !important;
    } */
}

@media (min-width: 641px) {
  .consultant_textbox {
    width: 100% !important;
    position: inherit !important;
  }
  .consualt_section {
    width: auto !important;
    text-align: center;
    padding-bottom: 20px;
    padding-right: 4px;
    padding-left: 5px;
  }
  .review_content {
    padding-right: 30px !important;
  }
}
@media (min-width: 641px) {
  .css-1ss2ei0-MuiContainer-root {
    max-width: 780px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .hiring_section img {
    width: 22rem;
  }
  .hire_header {
    font-size: 25px;
  }
  .hire_text {
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .hire_button {
    margin-bottom: 2rem;
  }
  .popup-content {
    width: 90%;
  }
  .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 16px !important;
  }
  .portfolio_bannersc {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* .technology_content{
    display: none;
  } */
}

/* REVIEW */
.customer_section {
  background-image: url(../../public/Testimonial_3.webp);
  background-size: cover;
  background-size: cover;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
.client_img {
  /* width: 80%; */
  /* margin: 10%; */
  width: 100%;
  margin: 10px;
}

.review_content {
  background-image: url(../../public/Testimonial_3.webp);
  background-size: cover;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: 8px;
  padding-left: 30px;
  padding-right: 0px;
  width: auto !important;

  /* background-image: url(../../public/Testimonial_3.webp);
    background-size: cover;
    background-size: cover;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative; */
}

.icon_size {
  font-size: 60px !important;
  color: #b52e31;
  transform: rotateY(180deg) !important;
}
.customer_content {
  background: #ffffff9c;
  border-radius: 10px;
  /* padding: 40px; */
  margin: 30px;
  width: 95% !important;
}
.review_hedaertext {
  color: #1c5798;
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 500;
  padding-left: 20%;
  padding-top: 50%;
  transform: translateY(-35%);
  text-shadow: 1px 3px #9cf5f8;
}

@media only screen and (max-width: 766px) {
  /* .technology_content{
      display: none;
    } */
  .client_img {
    /* width: 100%; */
    width: 4rem;
    height: 3rem;
    margin: 10%;
  }
  .review_content {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .customer_content {
    width: auto !important;
    margin: 0px !important;
  }
  .review_hedaertext {
    padding-left: 5%;
    padding-top: 20%;
    font-size: 25px;
  }
}

/* PORTFOLIO */

.portfolio_bannersc {
  padding-top: 14rem !important;
  padding-bottom: 14rem;
  background-image: url("../../public/portfolio.webp");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.portfolio_banner_conatiner {
  justify-content: left;
  display: flex;
  width: 100%;
  /* padding-top: 10%; */
  padding-left: 70px;
}
.portfolio_container {
  align-items: center;
  width: 100%;
}
.banner_small_text {
  color: #fff;
  font-family: sans-serif;
  width: 58%;
  font-size: 15px;
  line-height: 25px;
  margin-block-start: auto;
  /* text-shadow: 1px 0px #85f2f6;
    color: #1c5793; */
}
.portfolio_worksc {
  padding-top: 30px;
  /* padding-bottom: 30px; */
}
.css-1eoe787-MuiSlider-markLabel {
  color: #1e5e9d !important;
  font-size: 18px !important;
}
.project_container {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 14px;
  padding-right: 14px;
}
.project_img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 766px) {
  .portfolio_banner_conatiner {
    padding-left: 30px;
  }

  .portfolio_bannersc {
    /* height: 120px !important; */
    height: 40px !important;
    padding-top: 5rem !important;
    padding-bottom: 5rem;
  }
  .bold_txt {
    font-size: 19px;
  }
  .banner_small_text {
    font-size: 8px;
    line-height: 10px;
  }
  .portfolio_container {
    margin-top: 6%;
  }
  .portfolio_worksc {
    padding-top: 0px;
  }
  .css-1eoe787-MuiSlider-markLabel {
    font-size: 11px !important;
  }
}
@media only screen and (min-width: 480px) {
  .portfolio_bannersc {
    height: 220px;
  }
  .carousel_text {
    font-size: 36px;
  }
  /* .sub_container{
        margin-left: 0rem ;
    margin-right: 0rem 
      } */
}

.Notfound_content {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 62%;
}
.not_founf_gif {
  margin-top: 0;
}
.notfound_conatiner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 2rem;
}
.notFound_bannersc {
  padding-top: 4rem;
  padding-bottom: 14rem;
  background-color: #000;
  background-size: cover;
}
.notfound_text {
  color: #1e5e9d;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  line-height: 1.5rem;
}
.justify_tophead {
  justify-content: flex-end;
}
.hero_container {

  background: transparent;
}
.hero_container > img {
  width: 100%;
  height: auto;
}
.hero_container::before {
  /* content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  top: 0px;
  right: 0px; */
}

