html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.css-1djk842 {
  text-align: center !important;
}
.app_bannersc {
  padding-top: 4rem;
  padding-bottom: 4rem;
  /* background-image: url("../../public/servicesbanner.webp"); */
  background-size: cover;
  background: #40bcc1;
}

.appbanner_textbox {
  /* display: flex; */
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
  position: absolute;
  top: 10rem;
  left: 4.5rem;
}

.appbanner_txt {
  color: #0061a5;
  font-size: 17px;
  font-weight: 700;
  /* text-shadow: 1px 2px #0000ff30; */
  font-family: sans-serif;
}
.appbanner_headertext {
  color: #0061a5;
  /* font-size: 45px; */
  font-weight: 500;
  text-shadow: 1px 2px #0000ff30;
  font-family: sans-serif;
  /* padding-top: 2rem; */
  line-height: 2.4rem;
  width: 80%;
}
.app_smalltext {
  font-weight: 300;
  font-size: 13px;
  line-height: 1rem;
  font-family: sans-serif;
  margin-top: 2rem;
  color: #fff;
}
.app_smallfont {
  font-weight: 300;
  font-size: 13px;
  line-height: 1.2rem;
  font-family: sans-serif;
  margin-top: 1rem;
  color: #000;
  text-align: justify;
}
.appcontact_btn {
  width: 100%;
  /* display: flex; */
  justify-content: right;
  margin-top: 1rem;
}
.appcontact_btn button {
  color: #fff;
}
.gradient_btn {
  width: 280px;
  padding: 12px !important;
  border-radius: 30px;
  background: linear-gradient(to right, #2cc7f9 0%, #1747f0 100%);
  border: none;
  outline: none;
  font-family: Roboto;
  /* font-size: 20px; */
  font-weight: 600;
  color: #fff;
  box-shadow: 10px 15px 20px #00000038 !important;
}
.inner_banner_img {
  /* height: 24rem; */
  width: 100%;
}
.app_bg {
  padding-top: 60px;
  /* padding-bottom: 20px; */
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}
.app_detail_container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 6px #0061a5;
  margin-top: 5%;
  padding: 30px;
  position: relative;
  z-index: 2;
  margin: 4rem;
}
.detail_img_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app_image {
  width: 65%;
}
.app_detail_txt {
  color: #0061a5;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  /* text-shadow: 1px 3px #9cf5f8; */
  text-align: left;
  padding-top: 2%;
  /* margin-block-start: auto;
    margin-block-end: 25px */
}
.graph_container {
  /* padding-top: 10px; */
  padding-bottom: 60px;
  position: relative;
}

.facility_bg {
  padding-top: 60px;
  padding-bottom: 10px;
  position: relative;
}
.facility_container {
  background: rgba(0, 97, 165, 0.1);
  box-shadow: 0px 4px 0px rgba(0, 97, 165, 0.1);
  padding: 30px;
  position: relative;
  z-index: 2;
}
.facility_content_box {
  width: 80%;
  height: 250px;
  position: relative;
  padding-top: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #0061a5;
  /* margin-top: 4%; */
  text-align: center;
}
/* .facility_content_box:hover{
  background-color: #3494f4;
  color: #ffff ;
} */
.app_facility_txt {
  font-family: sans-serif;
  /* font-size: 17px; */
  font-weight: 500;
  color: #1a5493;
  padding-top: 4%;
  /* text-shadow: 1px 3px #9cf5f8 */
}
/* .app_facility_txt:hover{
  color: #ffff ;

} */
.techstack_text_bg {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}
.app_text {
  font-weight: 300;
  line-height: 1.4rem;
  font-family: sans-serif;
  margin-top: 2rem;
  text-align: justify;
  width: 80%;
}
.app_header_txt {
  color: #000;
  font-family: sans-serif;
  /* font-size: 30px; */
  font-weight: 700;
  /* text-shadow: 1px 3px #9cf5f8; */
  text-align: left;
  padding-top: 2%;
}
.img_png {
  width: 50px !important;
  height: 45px !important;
  padding: 0.1rem;
}
.text_paragrah {
  color: #000;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding-right: 10px;
  text-align: justify;
  padding-top: 0.5rem;
}
.app_know_txt {
  /* font-size: 20px; */
  color: #189098;
  font-family: sans-serif;
}
.app_sub_container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 40px 40px 70px 40px;
  position: relative;
  z-index: 2;
  margin: 4rem;
}
.icon_box_round {
  width: 150px;
  height: 60px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 4px 36px #765eec3d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bold_header {
  color: #1a5493;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-shadow: 1px 3px #9cf5f8;
  text-align: left;
  padding-top: 2%;
  margin-block-start: auto;
  margin-block-end: 25px;
}
.center {
  text-align: center;
}
.large {
  font-size: 40px;
}
.small_font {
  font-size: 13px !important;
}
.graph {
  width: 100%;
  margin-top: 1rem;
}
.margin_top {
  margin-top: 3rem;
}
.padding_0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.width {
  width: 70px !important;
}
.margin_bttm {
  margin-bottom: 0rem !important;
}
.grid_center {
  text-align: -webkit-center !important;
}
.grid_right {
  text-align: -webkit-right !important;
}
.ml-7 {
  margin-left: 19rem !important;
}
.faq_bg {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 9px;
  position: relative;
}
.faq_bg::after{
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.faq_container {
  /* padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-size: cover; */

  background: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 45px #6566ee3d;
    margin-top: 5%;
    padding: 40px 20px 70px 20px;
    position: relative;
    z-index: 2;
    /* margin: 4rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
}
.faq_text_container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.faq_text {
  color: #1b5493;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 300;
  padding-right: 10px;
  text-align: center;
  width: 100%;
  padding-top: 0rem;
  line-height: 1.5rem;
}
#panel2a-header {
  background: #3494f4;
  color: #fff;
  margin-bottom: 1rem;
  border-radius: 10px;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important ;
}
.w-90 {
  width: 90% !important;
}
.topheader_txt svg:hover{
  color: #5deef3;
}
.topheader_txt h6{
  font-size: 14px;
  color: #fff;
  padding: 3px 0px 0px 3px;
}
.topheader_txt h6:hover{
  color: #5deef3;
  font-size: 14px;
  padding: 3px 0px 0px 3px;
}
@media only screen and (max-width: 766px) {
  .faq_bg{
    padding-top: 0px !important;
  }
  .faq_bg::after{
    display: none;
  }
  .faq_container {
    box-shadow: none !important;
    /* padding: 40px 40px 0px 40px !important; */
    margin: 0.2rem !important;
    margin-top: 0rem !important
  }
  .faq_text{
    text-align: justify !important;
  }
  .css-11ozso8{
    width: 100% !important;
    text-align: justify;
  }
  .web_responsive {
    display: none;
  }
  .facility_content_box {
    width: 90%;
  }
  .app_banner_img {
    height: 8rem !important;
  }
  .faq_container {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .app_bannersc {
    height: 12rem !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .appbanner_headertext {
    width: 100%;
    font-size: 13px;
    line-height: 1rem;
  }
  .appbanner_textbox {
    top: 9rem;
    width: 50%;
    left: 1rem;
  }
  .app_smalltext {
    line-height: 0.7rem;
    margin-top: 0.5rem;
    font-size: 8px;
  }
  .gradient_btn {
    width: 170px;
    padding: 7px !important;
    font-size: 9px !important;
  }
  .inner_banner_img {
    width: 100%;
    height: 24rem;
  }
  .app_detail_container {
    margin: 1rem;
  }
  .app_detail_txt {
    font-size: 16px;
    padding-top: 0%;
  }
  .app_smallfont {
    margin-top: 0rem;
    font-size: 12px;
    text-align: justify;
  }
  .facility_bg {
    padding-top: 0px;
  }
  .app_text {
    width: 100%;
  }
  .app_sub_container {
    padding: 25px 5px 0px 15px !important;
    margin: 0rem !important;
  }
  .text_paragrah {
    width: 100%;
    font-size: 12px;
  }
  .app_know_txt {
    font-size: 13px;
  }
  .bold_header {
    text-align: center;
    font-size: 20px;
  }
  .ml-7 {
    margin-left: 0rem !important;
  }
  .grid_right {
    text-align: -webkit-center !important;
  }
  .app_sub_container {
    padding: 20px !important;
    text-align: -webkit-center;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .faq_bg::after{
    display: none;
  }
  .faq_container{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .appbanner_textbox {
    width: 50%;
    left: 1rem;
  }
  .facility_content_box {
    width: 100%;
    height: 200px;
  }
  .graph {
    width: 100%;
    margin-top: 7rem;
  }
  .app_sub_container {
    margin: 1rem;
  }
  .text_paragrah {
    width: 100%;
  }
  .app_detail_container {
    margin: 1rem;
  }
  .app_image {
    width: 90%;
  }
}

/* TIMELINE */

.vertical-timeline::before {
  width: 2px !important;
  background: #1747f0 !important;
}
.jzXBtE {
  display: none !important;
}
.dRthWm {
  margin-top: -3rem;
}
.gnfenX.active {
  background: #ecf3f8 !important;
  padding: 4px 10px;
}
.fMxotn {
  background: #cecece !important;
  height: 1px !important;
}
.igUvje:not(.using-icon) {
  background: #80f2f6 !important;
}
.igUvje:not(.using-icon).active::after {
  background: #67d7b8 !important;
}
.gnfenX {
  font-family: sans-serif;
  font-weight: 100 !important;
}
.bZaoTA.horizontal {
  min-width: -webkit-fill-available !important;
}
.QcBhP {
  min-height: auto !important;
}
.hvCzsb {
  display: grid !important;
  grid-template-columns: 20% 20% 20% 20% 20% !important;
  background: transparent !important;
}
.active_view {
  width: "10px";
  height: "10px";
  background-color: "blue";
  border-radius: "5px";
  margin-right: "10px";
}
.de_active_view {
  width: "10px" !important;
  height: "10px" !important;
  background-color: "black" !important;
  border-radius: "5px" !important;
  margin-right: "10px" !important;
}
.timeline_event {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.timeline_event ::after {
  border: 1px solid gray;
}
.view_work {
  text-align: center;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.timeline_dot {
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 6rem;
}
.timeline_dot:active{
  width: 12px;
  height: 12px;
  background-color: #d13fb5;
  border-radius: 7px;
  margin-right: 10px;
  margin-left: 6rem;
  outline: 2px solid blue;
}
.timeline_dot_active{
  width: 12px;
  height: 12px;
  background-color: #d13fb5;
  border-radius: 7px;
  margin-right: 10px;
  margin-left: 6rem;
  /* border: 2px solid rgb(229, 245, 9); */
}
.timeline_dot::before{
  /* content: ""; */
  /* border: 1px solid black; */
 
  content: "";
  display: table;
  position: absolute;
  z-index: 1;
  /* left: 10px; */
  top: 0;
  bottom: 0;
  /* border: solid #00B1F1; */
  /* border-width: 0 0 0 4px; */
  border: 1px solid gray;
  width: 170px;
  margin-top: 0.2rem;

}
.timeline_dot::after{
  content: "";
  display: table;
  border: 1px solid gray;
  width: 130px;
  margin-top: 0.2rem;
  position: absolute;
  left: 10px;
}