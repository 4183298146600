

.terms_section{
    padding-top: 30px;
    padding-bottom: 30px
}

.terms_container{
    position: relative;
    width: 100%;
    padding-right: 8px;
    padding-left: 8px
}
.terms_container h3{
    color: hsla(0, 0%, 0%, 0.639);
    font-family: sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 20px;
    text-align: center
}
.terms_container h4{
    color: #000000a3;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 18px;
    text-align: center;
}
.terms_container h5{
    color: blue;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 13px;

}
.terms_container p{
    color: #000;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding-top: 13px;
}
.terms_footer_box{
    width: 100%;
    height: 65px;
    background: #000000a6
}
.terms_footer_box h6{
    color: #fff;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 8px;
    text-align: center;
}
.terms_footer_txt p{
    color: #fff;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
}


@media only screen and (max-width: 766px){
    /* .css-mhc70k-MuiGrid-root>.MuiGrid-item{
        padding-left: 0px !important
    } */
}