html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.upload_btn{
  display: inline-block !important;
  width: 80%;
  padding: 8px;
  background: linear-gradient(45deg, #1a5290, #1b5496);
  box-shadow: 0px 4px 20px #6e62ed4f;
  border-radius: 30px;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-1%);
  margin-top: 5% !important;
  border: none;
  color: #FFF !important;
  text-transform: capitalize !important;
}
.carrer_bannersc {
  padding-top: 14rem !important;
  padding-bottom: 14rem;
  background-image: url("../../public/careerbanner.webp");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.career_header_content {
  justify-content: left;
  display: flex;
  height: 200px;
}
.career_header_text {
  color: #fff;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 35px;
  padding-top: 5%;
  padding-right: 13%;
  text-shadow: 1px 1px #85f2f6;
}
.career_container {
  padding-top: 10px;
  padding-bottom: 10px;
}
.img_box {
  height: 300px;
  width: 80%;
}
.career_content {
  text-align: center;
  font-family: sans-serif;
  color: #1c589b;
  /* margin-top: 5%; */
  text-shadow: 2px 2px #85f2f6;
}
.career_button_position{
  align-self: self-end;
    /* padding-left: 10px; */
    width: 55%;
    /* padding-bottom: 18px; */
    text-align: initial;
}
.career_popup_txt{
  align-self: flex-start;
  font-family: sans-serif;
  font-size: 18px;
}
.career_details {
  /* padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 14px; */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 40px 20px 70px 20px;
  position: relative;
  z-index: 2;
  margin: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 4.6rem;
  margin-right: 4rem;
}
.career_bg{
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.career_bg::before{
  content: "";
    width: 450px;
    height: 450px;
    border-radius: 100%;
    background-color: #ea53c626;
    position: absolute;
    top: 0px;
    left: 0px;
}
.career_bg::after{
  content: "";
    width: 450px;
    height: 450px;
    border-radius: 100%;
    background-color: #ea53c626;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.career_box_height{
  height: 7rem;
}
.detail_box {
  /* padding-top: 20px;
  padding-bottom: 20px; */
  margin: 5px;
  padding: 10px;
  padding-left: 30px;
  border-radius: 15px;
  border: 1px solid #c0c0c0;
  box-shadow: 0px 24px 20px #00000021 !important;
}
.box_header{
  font-family: sans-serif;
  color: #1c589b;
  margin-block-end: auto;
  font-weight: bold;
}
.carrer_sub{
  align-items: center;
}
.career_font{
  font-family: sans-serif;
  padding-top: 0.8rem;
  font-size: 12px;
  /* color: #000; */
  /* margin: 10px; */
}
.carrer_alignment{
  padding-left: 14px;
  border-bottom: 1px dotted #1e5e9a;
  padding-bottom: 4px
}
.career_icon{
 color: #000;
 margin-top: 10px;
    margin-right: 4px;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .img_box{
    height: auto;
  }
  .career_container{
    text-align: center;
  }
  .carrer_bannersc{
    padding-top: 10rem !important;
    padding-bottom: 10rem;
  }
  .career_details{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .career_bg{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

@media only screen and (max-width: 766px) {
  .career_details{
    box-shadow: none !important;
    padding: 0px !important;
    margin: 0.2rem !important;
    margin-top: 0rem !important;
  }
  
  .career_bg{
    padding-top: 10px !important;
  }
  .career_bg::before{
    display: none;
  }
  .career_bg::after{
    display: none;
  }
  .carrer_bannersc{
    height: 40px !important;
    padding-top: 5rem !important;
    padding-bottom: 5rem;
  }
  .career_header_text{
    font-size: 13px;
    padding-top: 12%;
    padding-left: 5%
  }
  .career_container{
    text-align: center;
  }
  .career_details{
    padding-left: 0px
  }
}
