html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.about_bannersc {
  padding-top: 14rem !important;
  padding-bottom: 14rem;
  background-image: url("../../public/about.webp");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.banner_textbox {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 410px; */
}
.goal_textbox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.goal_img1 {
  padding-bottom: 7.5rem;
  padding-top: 4rem;
  background-image: url("../../public/Group 25.webp");
  background-size: contain;
  /* background-position: bottom; */
  background-repeat: no-repeat;
}
.goal_img2 {
  padding-bottom: 7.5rem;
  padding-top: 4rem;
  background-image: url("../../public/Group 26.webp");
  background-size: contain;
  /* background-position: bottom; */
  background-repeat: no-repeat;
}
.goal_img3 {
  padding-bottom: 7.5rem;
  padding-top: 4rem;
  background-image: url("../../public/Group 27.webp");
  background-size: contain;
  /* background-position: bottom; */
  background-repeat: no-repeat;
}
.goal_text {
  color: #fff;
  font-size: 30px;
  /* font-weight: 700; */
  text-shadow: 1px 2px #69eff4;
  font-family: sans-serif;
  position: relative;
  top: 0rem !important;
  left: 1rem;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4.css-480o17-MuiGrid-root{
  padding-top: 10px;
    padding-bottom: 10px;
}

.banner_txt {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  text-shadow: 1px 2px #69eff4;
  font-family: sans-serif;
  position: absolute;
  top: 18rem;
}
.about_bg{
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}
.about_bg::after{
  content: "";
  width: 500px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.about_goal_section{
  background: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 45px #6566ee3d;
    padding-left: 1rem;
    margin-bottom: 3rem;
}
.about_detail_container {
  /* padding-top: 10px;
  padding-left: 20px;
  background-color: #fff; */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 40px 20px 70px 20px;
  position: relative;
  z-index: 2;
  margin: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 4.6rem;
  margin-right: 4rem;
}
.about_text {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  font-family: sans-serif;
  line-height: 1.6rem;
  padding-left: 15px
}
.about_goal_container {
  /* padding-top: 30px; */
  /* padding-bottom: 30px; */
  background: #fff;
  position: relative;
  overflow: hidden;
  padding-left: 15px;
}
.about_goal_text {
  color: #6c6ceb;
  font-weight: 700;
  font-size: 30px;
  /* margin-top: -25px */
}
.small_txt {
  font-size: 14px;
  font-weight: 200;
  padding-top: 4%;
  color: #000;
  font-family: sans-serif;
  line-height: 1.5rem;
  width: 94%;
  text-align: justify;
}
.about_goal_imgstyle {
  width: "92%" !important;
  margin-top: "4%" !important;
}
.client_bg{
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}

.client_bg::after{
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  top: 0px;
  left: 0px;
}

.client_sub_container{
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  /* margin-top: 5%; */
  padding: 40px 20px 70px 20px;
  position: relative;
  z-index: 2;
  margin: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* margin-left: 4.6rem;
  margin-right: 4rem; */

}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .client_bg::after{
    display: none;
  }
  .technology_content{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .client_sub_container{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .small_txt{
    width: 95%;
    padding-top: 0% !important
  }
  
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4.css-480o17-MuiGrid-root{
    padding-top: 5px;
      padding-bottom: 5px;
  }
  .about_bannersc{
    padding-top: 10rem !important;
    padding-bottom: 10rem;
  }
  .about_detail_container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .about_goal_container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .about_text {
    text-align: justify;
    padding-left: 16px;
    padding-right: 16px;
  }
  .banner_txt {
    top: 16rem;
  }
  .goal_img1 {
    padding-bottom: 14rem;
    background-size: contain
  }
  .goal_img2 {
    padding-bottom: 14rem;
    background-size: contain
  }
  .goal_img3 {
    padding-bottom: 14rem;
    background-size: contain
  }
}

@media only screen and (max-width: 766px) {
  .about_bg {
    padding-top: 0px !important;
  }
  .about_bg::after {
    display: none !important;
  }
  .about_detail_container{
    box-shadow: none !important;
    padding: 0px !important;
    margin: 0.2rem !important;
    margin-top: 0rem !important;

  }
  .technology_content {
    box-shadow: none !important;
    padding: 40px 40px 0px 40px !important;
    margin: 0.2rem !important;
    margin-top: 0rem !important
  }
  .client_sub_container {
    box-shadow: none !important;
    /* padding: 40px 40px 0px 40px !important; */
    margin: 0.2rem !important;
    margin-top: 0rem !important
  }
  .client_bg::after{
    display: none;
  }
  .hiring_bg::before{
    display: none;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4.css-480o17-MuiGrid-root{
    padding-top: 5px;
      padding-bottom: 5px;
  }
  .small_txt{
    width: 95%;
    padding-top: 0% !important
  }
  .about_text{
    text-align: justify;
    padding-left: 0px;
  }
  .about_detail_container{
    padding-left: 0px;
  }
  .about_goal_container{
    padding-left: 0px;
  }
  .goal_img1{
    padding-top: 0rem !important;
    background-size: contain
  }
  .goal_img2{
    padding-top: 0rem !important;
    background-size: contain
  }
  .goal_img3{
    padding-top: 0rem !important;
    background-size: contain
  }
  .banner_textbox{
    align-items: flex-start !important;
    padding-top: 10px;
  }
  .goal_text{
    left: 1rem !important;
    top: 2.5rem !important;
    /* font-size: 25px !important; */
  }
  .about_bannersc {
    height: 40px !important;
    padding-top: 5rem !important;
    padding-bottom: 5rem;
  }
  .banner_txt {
    top: 10rem;
    font-size: 22px;
  }
  .about_goal_imgstyle {
    width: "92%" !important;
    margin-top: "4%" !important;
  }
}

@media only screen and (min-width: 480px) {
  .goal_text{
    top: -2.5rem;
  }
}
