html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.services_bannersc {
  background-image: url("../../public/servicesbanner.webp");
  padding-top: 14rem !important;
  padding-bottom: 14rem;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.ourservice_bg{
  position: relative;
}
.ourservice_bg::after{
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ourservice_bg::before{
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.services_content {
  /* padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 5px;
  position: relative; */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 20px 20px 10px 20px;
  position: relative;
  z-index: 2;
  margin: 4rem;
  margin-top: 1rem;
  /* margin-bottom: 1rem; */
  margin-left: 4.6rem;
  margin-right: 4rem;
}

.service_img_box {
  height: 16rem;
  width: auto;
}
.service_header_txt {
  color: #1c569a;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  text-shadow: 1px 2px #9cf5f8;
}
.services_box {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 10px 20px #00000038;
  height: 100%;
}
.services_text {
  color: #000;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  line-height: 1.5rem;
}
.work_bg{
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
}
.work_bg::after{
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  top: 0px;
  left: 0px;

}
.work_bg::before{
  content: "";
  width: 450px;
  height: 450px;
  border-radius: 100%;
  background-color: #ea53c626;
  position: absolute;
  bottom: 0px;
  right: 0px;

}
.work_container{
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 45px #6566ee3d;
  margin-top: 5%;
  padding: 40px 20px 70px 20px;
  position: relative;
  z-index: 2;
  margin: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 4.6rem;
  margin-right: 4rem;
}
.css-1gsv261{
  margin: auto;
    width: 20%;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .css-1gsv261{
    margin: auto;
      width: 30%;
  }
  .ourservice_bg{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .services_text {
    padding-top: 18rem !important;
    line-height: 1.8rem;
    font-size: 22px;
  }
  .services_bannersc {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .services_content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .service_header_txt {
    font-size: 24px;
  }
  .services_content {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.work_bg {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.work_container {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
}

@media only screen and (max-width: 766px) {
  .css-1gsv261{
    margin: auto;
      width: 70%;
  }
  .work_container {
    box-shadow: none !important;
    padding: 40px 40px 0px 40px !important;
    margin: 0.2rem !important;
    margin-top: 0rem !important
  }
  .work_bg{
    padding-top: 0px !important;
  }
  .work_bg::after{
    display: none;
  }
  .work_bg::before{
    display: none;
  }
  .services_content {
    box-shadow: none !important;
    padding: 0px !important;
    margin: 0rem !important;
    margin-top: 0rem !important
  }
  .ourservice_bg::after{
    display: none;
  }
  .ourservice_bg::before{
    display: none;
  }
  .services_bannersc {
    height: 40px !important;
    padding-top: 5rem !important;
    padding-bottom: 5rem;
  }

  .services_content {
    padding-left: 5px;
  }
}

@media only screen and (max-width: 480px) {
  .services_text{
    margin-top: 50px;
  }
}
